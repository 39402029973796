import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'gatsby-plugin-react-intl';

import InstantIcon from './images/instant.inline.svg';
import InstantLogo from './images/instant-logo.inline.svg';
import MarketIcon from './images/market.inline.svg';
import MarketLogo from './images/market-logo.inline.svg';
import LongArrowRightRegularIcon from './images/long-arrow-right-regular.inline.svg';
import SaleFeePromoBadge from './images/sale-fee-promo-badge.inline.svg';

import * as s from './Hub.module.css';

const messages = defineMessages({
  loginInstantHeadline: {
    id: 'hub.login.instant.headline',
    defaultMessage: `get instant cash for\nyour steam inventory`,
  },
  loginMarketHeadline: {
    id: 'hub.login.market.headline',
    defaultMessage: `buy new skins or sell\nyour own on the market`,
  },
  signUpInstantHeadline: {
    id: 'hub.signUp.instant.headline',
    defaultMessage: `start cashing out your\nsteam inventory now`,
  },
  signUpMarketHeadline: {
    id: 'hub.signUp.market.headline',
    defaultMessage: `begin trading skins for\ncash on the market`,
  },
  signUpCtaCaption: {
    id: 'hub.signUp.ctaCaption',
    defaultMessage: 'sign up',
  },
  loginCtaCaption: {
    id: 'hub.login.ctaCaption',
    defaultMessage: 'log in',
  },
});

export const VARIANT = {
  LOGIN: 'login',
  SIGN_UP: 'sign-up',
};

const content = {
  [VARIANT.LOGIN]: {
    instant: {
      id: 'hub-instant-login',
      headline: messages.loginInstantHeadline,
      url: '/sign-in?continue=/panel/deposit',
    },
    market: {
      id: 'hub-market-login',
      headline: messages.loginMarketHeadline,
      url: '/sign-in?continue=/market',
    },
    ctaCaption: messages.loginCtaCaption,
  },
  [VARIANT.SIGN_UP]: {
    instant: {
      id: 'hub-instant-signup',
      headline: messages.signUpInstantHeadline,
      url: '/sign-in?continue=/panel/deposit',
    },
    market: {
      id: 'hub-market-signup',
      headline: messages.signUpMarketHeadline,
      url: '/sign-in?continue=/market',
    },
    ctaCaption: messages.signUpCtaCaption,
  },
};

const Hub = ({ variant }) => {
  const { locale, defaultLocale, formatMessage } = useIntl();
  const { instant, market, ctaCaption } = content[variant];
  const isSaleFeePromoBadgeVisible = locale === defaultLocale;
  return (
    <section className={s.root}>
      <a id={instant.id} className={cx(s.column, s.instant)} href={instant.url}>
        <div className={s.content}>
          <InstantLogo className={s.logo} />
          <p className={s.caption}>{formatMessage(instant.headline)}</p>
          <span className={s.cta} href={instant.url}>
            <span>{formatMessage(ctaCaption)}</span>
            <LongArrowRightRegularIcon />
          </span>
        </div>
        <div className={s.icon}>
          <InstantIcon />
        </div>
      </a>
      <div className={s.divider} />
      <a id={market.id} className={cx(s.column, s.market)} href={market.url}>
        <div className={s.content}>
          <MarketLogo className={s.logo} />
          <p className={s.caption}>{formatMessage(market.headline)}</p>
          <span className={s.cta}>
            <span>{formatMessage(ctaCaption)}</span>
            <LongArrowRightRegularIcon />
          </span>
          {isSaleFeePromoBadgeVisible && (
            <div className={s.promoBadge}>
              <SaleFeePromoBadge />
            </div>
          )}
        </div>
        <div className={s.icon}>
          <MarketIcon />
        </div>
      </a>
    </section>
  );
};

Hub.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANT)),
};

Hub.defaultProps = {
  variant: VARIANT.LOGIN,
};

export default Hub;
